import styled from '@emotion/styled';
import { Column } from '@stenajs-webui/core';
import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoading } from '../../../common-ui/PageLoading';
import { breakpoints } from '../../../themes/defaultTheme';
import { AuthErrorBody } from '../../../use-cases/auth/AuthErrorBody';
import { authErrorTitle } from '../../../use-cases/auth/errors';
import { ErrorPage } from '../../error-handling/ErrorPage';
import { Footer } from '../../footer/Footer';
import { Header, useRailEnabled } from '../../header/components/Header';
import { useAuthState } from '../../hooks/useAuthState';
import { useTrackPageView } from '../../tracking/useTrackPageView';
import { AccountSettingsGate } from './AccountSettingsGate';
import { Login } from './Login';
import { Main } from './Main';

const AppWrapper = styled(Column)<{ isRailEnabled?: boolean }>`
  height: 100%;

  .manage-page & {
    height: 100dvh;
    min-height: 768px;
  }

  --nav-menu-width: 0px;

  @media (min-width: ${breakpoints.sm}) {
    --nav-menu-width: ${props => (props.isRailEnabled ? '60px' : '0px')};
  }

  @media print {
    --nav-menu-width: 0px;
  }

  .ReactModal__Body--open & {
    @media print {
      display: none !important;
    }
  }
`;

export const AppLoading = () => (
  <AppWrapper className="t_extranet_app">
    <PageLoading />
  </AppWrapper>
);

export const App: FC = () => {
  const { isAuthenticated, sessionFetched, error } = useAuthState();
  const railEnabled = useRailEnabled();
  useTrackPageView();

  if (error) {
    return (
      <ErrorPage
        title={'Login error - ' + authErrorTitle(error)}
        content={<AuthErrorBody error={error} />}
      />
    );
  }

  if (!sessionFetched) {
    return <AppLoading />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <AppWrapper className="t_extranet_app" isRailEnabled={railEnabled}>
      <Routes>
        <Route
          path={'/*'}
          element={
            <AccountSettingsGate>
              <Header />
              <Suspense fallback={<AppLoading />}>
                <Main />
              </Suspense>
              <Footer />
            </AccountSettingsGate>
          }
        />
      </Routes>
    </AppWrapper>
  );
};
