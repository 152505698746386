import { put, take, call, select } from 'redux-saga/effects';
import { trackEvent } from '../../../common/tracking/trackerService';
import { resolveCustomsDocumentationEnsURL } from '../../../common/utils/customsEnsUtils';
import { getUserNameWithPrefix } from '../../authentication/authReducer';
import { getCookieConsent } from '../../cookie-consent/slice';
import { OpenOptionalEnsAfterSaveAction, requestSubmit } from '../updateFormActions';
import { getUpdateFormState } from '../updateFormReducer';

export function* optionalEnsSaga({ payload: { id } }: OpenOptionalEnsAfterSaveAction) {
  yield put(requestSubmit(id));
  yield take('UPDATE_FORM:SUBMIT_FINISHED');

  const state: ReturnType<typeof getUpdateFormState> = yield select(getUpdateFormState, id);

  if (
    state?.meta.updateResult?.result?.success &&
    !state.meta.updateResult.result.waitlistPossible &&
    state.booking
  ) {
    const userId: ReturnType<typeof getUserNameWithPrefix> = yield select(getUserNameWithPrefix);
    const consent: ReturnType<typeof getCookieConsent> = yield select(getCookieConsent);

    const url = resolveCustomsDocumentationEnsURL(state.booking.ensUrl, userId, consent);

    yield call(() => {
      if (window.open(url, '_blank')) {
        trackEvent({ category: 'Details', action: `Pressed optional ENS link (after save)` });
      } else {
        trackEvent({
          category: 'Details',
          action: 'Could not open optional ENS link (after save)',
        });
      }
    });
  }
}
