import { difference } from 'lodash';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { assertIsDefined } from '../../common/asserts/assert';
import { getTourIds } from '../../common/hooks/utils/newFeatureTourUtil';
import { isOnManagePage } from '../../common/routes/routeUtils';
import {
  getIntroductionTourSession,
  saveIntroductionTourSession,
} from '../../store/sessionStorage';
import { getNewFeatureTour } from '../../use-cases/tour/tours/NewFeatureTour';
import { shouldShowNewSubscriptions } from '../../use-cases/user-subscriptions/util/migratedSubscription';
import { getOverrideMainPage } from '../authentication/utils/authUtils';
import { showIntroduction } from '../modal/modalActions';
import { getAccount } from '../settings/settingsReducer';
import { RequestInitializeTourAction, startNewFeatureTour } from './tourActions';

export function* startFeatureTourSaga(action: RequestInitializeTourAction) {
  const account: ReturnType<typeof getAccount> = yield select(getAccount);
  assertIsDefined(account, 'Account is not initialized');

  const shouldShowSubscriptions = shouldShowNewSubscriptions(account.accountSettings);

  const currentTourIds = getTourIds(getNewFeatureTour(shouldShowSubscriptions).steps);
  const unseenFeaturesIds = difference(currentTourIds, action.payload.seenFeatureIds);
  const isNextSession = !getIntroductionTourSession();

  if (isNextSession && unseenFeaturesIds.length > 0) {
    yield put(startNewFeatureTour(unseenFeaturesIds));
  }
}

export function* showIntroductionSaga(action: RequestInitializeTourAction) {
  const account: ReturnType<typeof getAccount> = yield select(getAccount);
  assertIsDefined(account, 'Account is not initialized');

  const shouldRunTour =
    isOnManagePage(window.location) && getOverrideMainPage(account.permissions) == null;

  if (shouldRunTour) {
    if (!action.payload.introductionTourSeen) {
      yield put(showIntroduction());
      yield call(saveIntroductionTourSession);
    } else {
      yield take('GRID:SET_QUERY_RESULT');
      yield fork(startFeatureTourSaga, action);
    }
  }
}

export function* watchTourSaga() {
  yield takeEvery('TOUR:REQUEST_INITIALIZE', showIntroductionSaga);
}
