import styled from '@emotion/styled';
import { Box, Row, SeparatorLine, Space, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { ModalHeader } from '../../use-cases/manage/details/components/modal/ModalHeader';
import { HotkeyGroup } from './utils/hotkeys';
import { getHotkeyByGroup } from './utils/hotkeysUtils';

export interface HotkeysModalProps {
  onClose: () => void;
}

const RowItem = styled('div')`
  min-width: 200px;
`;

const EmptyRow = styled('div')`
  min-height: 35px;
`;

interface HotkeyRowProps {
  left: string;
  right: string;
  bold?: boolean;
  index?: number;
}

const HotkeyRow: FC<HotkeyRowProps> = ({ left, right, bold = false }) => {
  return (
    <>
      <Row alignItems={'center'} spacing>
        <RowItem>
          <Txt variant={bold ? 'bold' : 'standard'}>{left}</Txt>
        </RowItem>
        <RowItem>
          <Txt variant={bold ? 'bold' : 'standard'}>{right}</Txt>
        </RowItem>
      </Row>
      <SeparatorLine />
    </>
  );
};

interface HotkeyGroupRowProps {
  label: string;
}

const HotkeyGroupRow: FC<HotkeyGroupRowProps> = ({ label }) => {
  return (
    <>
      <Row alignItems={'center'} spacing>
        <RowItem>
          <Txt size={'small'} variant={'overline'}>
            {label}
          </Txt>
        </RowItem>
      </Row>
      <SeparatorLine />
    </>
  );
};

export const HotkeysModal: FC<HotkeysModalProps> = ({ onClose }) => {
  const generalKeys = getHotkeyByGroup(HotkeyGroup.General);
  const filterCreateKeys = getHotkeyByGroup(HotkeyGroup.FiltersCreate);
  const gridKeys = getHotkeyByGroup(HotkeyGroup.GridBooking);

  return (
    <div className={'tour_shortcuts_overview t_shortcuts_modal'}>
      <ModalHeader headerText={'Keyboard shortcuts'} onRequestClose={onClose} />

      <Box indent={3} spacing={3} style={{ minWidth: 400 }}>
        <Txt>Use keyboard shortcuts to speed up your workflow on the Manage page.</Txt>
        <Space num={3} />
        {<HotkeyRow left={'Action'} right={'Keyboard'} bold />}
        {generalKeys.map(({ action, binding }, index) => (
          <HotkeyRow key={index} left={action} right={binding} />
        ))}
        <EmptyRow />
        <SeparatorLine />
        {filterCreateKeys.map(({ action, binding }, index) => (
          <HotkeyRow key={index} left={action} right={binding} />
        ))}
        <EmptyRow />
        <SeparatorLine />
        <HotkeyGroupRow label={'Booking table'} />
        {gridKeys.map(({ action, binding }, index) => (
          <HotkeyRow key={index} left={action} right={binding} />
        ))}
      </Box>
    </div>
  );
};
