import { matchPath } from 'react-router-dom';
import { getOverrideMainPage } from '../../services/authentication/utils/authUtils';
import { AccountQuery_viewer_account_byEmail_permissions } from '../../use-cases/settings/graphql/gql/AccountQuery';
import { appRoutes, routeCreators } from './AppRoutes';

export const isOnManagePage = (location: { pathname: string }): boolean =>
  location.pathname === '/' ||
  matchPath(
    {
      path: appRoutes.bookings,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnPaymentPage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.payment,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnSimpleCreatePage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.create,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnSettingsPage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.settings,
      end: true,
    },
    location.pathname,
  ) != null;

export const getReturnToPath = (returnToParam: string | null): string => {
  if (returnToParam) {
    return returnToParam;
  }

  return routeCreators.bookings();
};

export const getMainPage = (permissions: AccountQuery_viewer_account_byEmail_permissions) => {
  const override = getOverrideMainPage(permissions);

  return override ?? routeCreators.bookings();
};
