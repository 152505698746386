import { Link } from '@stenajs-webui/elements';
import { forwardRef } from 'react';
import { useOptionalEnsLink } from '../../common/import-export/useOptionalEnsLink';
import { TrackerCategory } from '../../common/tracking/trackerService';

interface EnsLinkProps {
  ensUrl: string;
  disabled?: boolean;
  confirmed: boolean;
  optional?: boolean;
  trackerCategory: TrackerCategory;
}

export const EnsLink = forwardRef<HTMLAnchorElement, EnsLinkProps>(function EnsLink(
  { ensUrl, disabled, confirmed, optional, trackerCategory },
  ref,
) {
  const linkProps = useOptionalEnsLink(ensUrl, optional, trackerCategory);

  return (
    <Link
      className={'t_ens_link'}
      target={'_blank'}
      style={{ whiteSpace: 'nowrap' }}
      disabled={disabled}
      {...linkProps}
    >
      <span ref={ref}>
        {confirmed ? 'ENS Confirmed' : optional ? 'Declare ENS (optional)' : 'Declare ENS'}
      </span>
    </Link>
  );
});
