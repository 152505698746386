import { graphql } from '../../../gql';

export const accountQuery = graphql(/*GraphQL*/ `
  query AccountQuery {
    viewer {
      account {
        byEmail {
          customers {
            ...Customer
          }
          accountSettings {
            ...Setting
          }
          privacySettings {
            id
            customsLink
          }
          pageSettings {
            id
            ...PageSetting
          }
          permissions {
            viewBookings
            editBookings
            viewIntermodalBookings
            editIntermodalBookings
            invoices
            payment
            priceCalculation
            statistics
          }
        }
      }
    }
  }
`);
