import { FlatButtonLink, stenaCheck } from '@stenajs-webui/elements';
import { useOptionalEnsLink } from '../../common/import-export/useOptionalEnsLink';
import { TrackerCategory } from '../../common/tracking/trackerService';

interface EnsButtonLinkProps {
  ensUrl: string;
  confirmed: boolean;
  optional?: boolean;
  trackerCategory: TrackerCategory;
}

export function EnsButtonLink({
  ensUrl,
  confirmed,
  optional,
  trackerCategory,
}: EnsButtonLinkProps) {
  const linkProps = useOptionalEnsLink(ensUrl, optional, trackerCategory);

  return (
    <FlatButtonLink
      className={'t_ens_button_link'}
      target={'_blank'}
      label={confirmed ? 'ENS Confirmed' : optional ? 'Declare ENS (optional)' : 'Declare ENS'}
      leftIcon={confirmed ? stenaCheck : undefined}
      {...linkProps}
    />
  );
}
