import { Column } from '@stenajs-webui/core';
import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FeatureFlag } from '../../../services/flags/enum';
import { getFeatureIsEnabled } from '../../../services/flags/featureFlags';
import { SwitchModal } from '../../../use-cases/manage/switch/components/SwitchModal';
import { NewFeatureTour } from '../../../use-cases/tour/tours/NewFeatureTour';
import { CookieConsentPopup } from '../../cookie-consent/CookieConsentPopup';
import { BookingCancelModal } from '../../modal/BookingCancelModal';
import { BookingDetailsModal } from '../../modal/BookingDetailsModal';
import { FreightPortalModal } from '../../modal/FreightPortalModal';
import { IntermodalBookingCreateContainer } from '../../modal/IntermodalBookingCreateModal';
import { IntermodalBookingUpdateModal } from '../../modal/IntermodalBookingUpdateModal';
import { appRoutes, routeCreators } from '../../routes/AppRoutes';
import { SideEffects } from './SideEffects';
import { useBackgroundLocation } from './UseBackgroundLocation';

const HelpPage = lazy(() => import('../../../use-cases/help/HelpPage'));
const BookingClaimPage = lazy(
  () => import('../../../use-cases/manage/claims/screens/BookingClaimPage'),
);
const ClaimsPortalPage = lazy(
  () => import('../../../use-cases/manage/claims/screens/ClaimsPortalPage'),
);
const HazardousGoodsContainer = lazy(
  () => import('../../../use-cases/manage/hazardous-goods/HazardousGoodsContainer'),
);
const ManagePage = lazy(() => import('../../../use-cases/manage/list/components/ManagePage'));
const PaymentPage = lazy(() => import('../../../use-cases/payment/components/PaymentPage'));
const SettingsScreen = lazy(() => import('../../../use-cases/settings/SettingsScreen'));

const SimpleCreateForm = lazy(
  () => import('../../../use-cases/simple-create/components/SimpleCreateForm'),
);
const IntermodalPage = lazy(
  () => import('../../../use-cases/intermodal/overview/components/IntermodalPage'),
);
const TimetablePage = lazy(() => import('../../../use-cases/timetable/TimetablePage'));

const StatisticsScreen = lazy(
  () => import('../../../use-cases/statistics/components/StatisticsScreen'),
);

const NoShowLateHandlingPage = lazy(
  () => import('../../../use-cases/external-pages/NoShowLateHandlingScreen'),
);

const FinancialDocumentsPage = lazy(
  () => import('../../../use-cases/external-pages/FinancialDocumentsScreen'),
);

export const Main: FC = () => {
  const location = useBackgroundLocation();

  return (
    <Column background={'var(--bg-color)'} flex={1} style={{ marginLeft: 'var(--nav-menu-width)' }}>
      <NewFeatureTour />
      <SideEffects />
      <Routes location={location}>
        <Route path={appRoutes.bookings} element={<ManagePage />} />
        <Route path={appRoutes.hazardousGoods} element={<HazardousGoodsContainer />} />
        <Route path={appRoutes.create} element={<SimpleCreateForm />} />
        {getFeatureIsEnabled(FeatureFlag.IntermodalBook) && (
          <Route path={appRoutes.intermodal} element={<IntermodalPage />} />
        )}
        {getFeatureIsEnabled(FeatureFlag.Statistics) && (
          <Route path={appRoutes.statistics} element={<StatisticsScreen />} />
        )}
        <Route path={appRoutes.settings} element={<SettingsScreen />} />
        <Route path={appRoutes.timetable} element={<TimetablePage />} />
        <Route path={appRoutes.help} element={<HelpPage />} />
        <Route path={appRoutes.bookingClaims} element={<BookingClaimPage />} />
        <Route path={appRoutes.claimsPortal} element={<ClaimsPortalPage />} />
        <Route path={appRoutes.payment} element={<PaymentPage />} />
        {getFeatureIsEnabled(FeatureFlag.NoShowLateHandling) && (
          <Route path={appRoutes.noShowLateHandling} element={<NoShowLateHandlingPage />} />
        )}
        {getFeatureIsEnabled(FeatureFlag.FinancialDocuments) && (
          <Route path={appRoutes.financialDocuments} element={<FinancialDocumentsPage />} />
        )}
        <Route index element={<Navigate to={routeCreators.bookings()} replace />} />
        <Route path={'*'} element={<Navigate to={routeCreators.bookings()} replace />} />
      </Routes>

      <Routes>
        <Route path={appRoutes.bookingSwitch} element={<SwitchModal />} />
        <Route path={appRoutes.bookingCancel} element={<BookingCancelModal />} />
        <Route path={appRoutes.bookingDetailsEdit} element={<BookingDetailsModal isEditing />} />
        <Route path={appRoutes.bookingDetailsView} element={<BookingDetailsModal />} />
        {getFeatureIsEnabled(FeatureFlag.IntermodalBook) && (
          <Route path={appRoutes.intermodalDetails} element={<IntermodalBookingUpdateModal />} />
        )}
        {getFeatureIsEnabled(FeatureFlag.IntermodalBook) && (
          <Route path={appRoutes.intermodalCreate} element={<IntermodalBookingCreateContainer />} />
        )}
      </Routes>
      <FreightPortalModal />
      <CookieConsentPopup />
    </Column>
  );
};
