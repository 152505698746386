import { useCallback } from 'react';
import { getHasPermission, Permission } from '../../services/authentication/utils/authUtils';
import { useAccount } from '../../services/settings/hooks/useAccount';

export const useHasPermission = (): ((permission: Permission) => boolean) => {
  const { permissions } = useAccount();

  return useCallback(
    permission => {
      if (permissions) {
        return getHasPermission(permission, permissions);
      }

      return false;
    },
    [permissions],
  );
};
