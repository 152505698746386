import { DetailedBooking } from '../graphql/fragments/gql/DetailedBooking';
import {
  BasicBooking,
  BasicBooking_bookingStatus,
  BasicBooking_loadingPreference,
} from '../graphql/fragments/gql/BasicBooking';
import { GoodsApproval, GoodsApprovalType } from '../utils/GoodsApproval';
import {
  resolveLoadingPreferenceApproved,
  resolveLoadingPreferenceText,
} from '../utils/loadingPreferenceUtil';
import { filterMap } from '../utils/filterMap';
import {
  getApprovalHeader,
  getApprovalInfo,
} from '../../use-cases/manage/details/utils/approvalInfo';
import {
  resolveHazardousGoodsApproved,
  resolveHazardousGoodsText,
} from '../utils/hazardousGoodsUtil';
import { MinimalBooking } from '../../use-cases/manage/details/utils/minimalBooking';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../use-cases/import-export/util/ImportExportSettingsGetters';
import { ImportExportType } from '../../gql/graphql';
import autoTable, { RowInput } from 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { shouldShowHazardousGoodsApproval } from '../utils/shouldShowHazardousGoodsApproval';

export const formatVehicleDimensions = (booking: DetailedBooking) => {
  return `${booking.length}m × ${booking.width}m × ${booking.height}m`;
};
export const getIntermodalTemperatureText = (temperature: string | null) => {
  if (temperature === '' || !temperature) {
    return null;
  }
  return `${temperature} °C`;
};

export function openBlob(blob: Blob) {
  const url = URL.createObjectURL(blob);
  const popup = window.open(url);
  if (!popup) {
    return;
  }

  const intervalID = setInterval(() => {
    if (popup.closed) {
      URL.revokeObjectURL(url);
      clearInterval(intervalID);
    }
  }, 10_000);
}

export function getLoadingPreferencePrintInfo(
  loadingPreference: BasicBooking_loadingPreference | null,
  loadingPreferenceStatusCode: string,
) {
  const loadingPreferenceApproval: GoodsApproval = {
    status: resolveLoadingPreferenceApproved(loadingPreference, loadingPreferenceStatusCode),
    type: GoodsApprovalType.LoadingPreference,
  };

  const loadingPreferenceHeader = getApprovalHeader(loadingPreferenceApproval);
  const loadingPreferenceText =
    loadingPreferenceHeader === ''
      ? resolveLoadingPreferenceText(loadingPreference)
      : `${resolveLoadingPreferenceText(loadingPreference)} (${loadingPreferenceHeader})`;

  return { loadingPreferenceApproval, loadingPreferenceText };
}

export function getHazardousGoodsPrintInfo(
  hazardousGoods: boolean,
  hazardousGoodsApproved: boolean,
  bookingStatus: BasicBooking_bookingStatus,
) {
  const hazardousGoodsApproval: GoodsApproval = {
    status: resolveHazardousGoodsApproved(false, hazardousGoods, hazardousGoodsApproved),
    type: GoodsApprovalType.Hazardous,
  };

  const hazGoodsApprovalHeader = getApprovalHeader(hazardousGoodsApproval);
  const hazardousGoodsText =
    hazGoodsApprovalHeader === ''
      ? resolveHazardousGoodsText(hazardousGoods)
      : `${resolveHazardousGoodsText(hazardousGoods)} (${hazGoodsApprovalHeader})`;

  return {
    hazardousGoodsApproval: shouldShowHazardousGoodsApproval(
      bookingStatus.status,
      hazardousGoodsApproved,
    )
      ? hazardousGoodsApproval
      : null,
    hazardousGoodsText,
  };
}

export function getImportExportReferenceText(
  minimalBooking: MinimalBooking,
  booking: BasicBooking,
  type: 'exportReference' | 'importReference',
) {
  const exportSettings = getImportExportSettingsFromMinimalBooking(
    minimalBooking,
    getImportExportSettingsFromRoute(minimalBooking.route, type),
  );

  if (exportSettings?.type === ImportExportType.Ens && booking.importReference.ensConfirmed) {
    return 'ENS Confirmed';
  }

  return String(
    exportSettings?.type === ImportExportType.Scac ? booking.exportScacId : booking[type].reference,
  );
}

export function filterApprovalsForGoods(approvals: (GoodsApproval | null)[]) {
  return filterMap(approvals, a => (a ? getApprovalInfo(a, null, true) : null));
}

export const generateTablePdf = (headers: RowInput[] | undefined, body: RowInput[] | undefined) => {
  const doc = new jsPDF({ orientation: 'landscape' });

  autoTable(doc, {
    styles: {
      fontSize: 6,
      textColor: '#000',
      cellPadding: { vertical: 1, horizontal: 1 },
    },
    headStyles: {
      fillColor: false,
      textColor: '#000',
    },
    theme: 'striped',
    margin: 0,
    head: headers,
    body: body,
  });

  const blob = doc.output('blob');
  openBlob(blob);
};
