import { StoreState } from '../../store/storeState';
import { ErrorType } from '../../use-cases/auth/errors';
import { AuthResult } from '../../use-cases/auth/types';
import { AuthAction } from './authActions';
import { resolveUserDisplayName, resolveUserName } from './utils/authUtils';

export interface AuthError {
  type: ErrorType;
  description: string;
}

export interface AuthState {
  auth0SessionInformationFetched: boolean;
  authResult?: AuthResult;
  error: AuthError | null;
  shouldRedirect: boolean;
}

const INITIAL_VALUE: AuthState = {
  auth0SessionInformationFetched: false,
  authResult: undefined,
  error: null,
  shouldRedirect: false,
};

export const authReducer = (state: AuthState = INITIAL_VALUE, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'AUTH:USER_LOGGED_IN':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: action.payload.authResult,
      };
    case 'AUTH:NO_SESSION_FOUND':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: undefined,
      };
    case 'AUTH:FATAL_ERROR':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: undefined,
        error: { ...action.payload },
      };
    default:
      return state;
  }
};

const getAuth = (state: StoreState): AuthState => state.auth;

export const getAuthResult = (state: StoreState): AuthResult | undefined =>
  getAuth(state).authResult;

export const getUserNameWithPrefix = (state: StoreState): string => {
  const authResult = getAuthResult(state);
  return authResult ? resolveUserName(authResult) : '';
};

export const getUserDisplayName = (state: StoreState): string => {
  const authResult = getAuthResult(state);
  return authResult ? resolveUserDisplayName(authResult) : '';
};

export const isAuth0SessionInformationFetched = (state: StoreState): boolean => {
  return getAuth(state).auth0SessionInformationFetched;
};

export const isAuthenticated = (state: StoreState): boolean => {
  const authResult = getAuthResult(state);
  return authResult && authResult.expiresAt ? new Date().getTime() < authResult.expiresAt : false;
};

export const getAuthError = (state: StoreState): AuthError | null => {
  return getAuth(state).error;
};
