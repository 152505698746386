import { BookingsSortColumn } from '../gql/graphql';
import { FilterState } from '../services/booking-filter/filterReducer';
import { browserHistory } from '../store/utils/history';
import { urlGetSearchString, urlReplaceSearchString } from '../store/utils/urlModifier';
import { URLHashData } from './booking-filters/types';
import { parseManagePageUrl, stringifyManagePageUrl } from './booking-filters/urlFilterState';

export const loadFilter = (): Partial<URLHashData> => {
  const searchString = urlGetSearchString(browserHistory);

  return parseManagePageUrl(searchString);
};

export const saveURLFilterData = (filter: FilterState, sorting: BookingsSortColumn) => {
  urlReplaceSearchString(browserHistory, stringifyManagePageUrl(filter, sorting));
};
