import { flatMap } from 'lodash';
import { ColumnId, ImportExportType } from '../../../gql/graphql';
import { CreateCandidate, CreateFormState } from '../../../services/create/createFormReducer';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../import-export/util/ImportExportSettingsGetters';
import { BookingFields, getFieldsToShow } from '../../manage/details/utils/detailsUtils';
import { transformCreateBookingFormStateToMinimalBooking } from '../../manage/details/utils/minimalBooking';

export const getCreateCandidateFormFields = (
  candidate: CreateCandidate,
  createFormState: CreateFormState,
): BookingFields => {
  const minimalBooking = transformCreateBookingFormStateToMinimalBooking(
    createFormState,
    candidate,
  );

  const columns = getFieldsToShow(
    minimalBooking,
    Boolean(createFormState.customer?.isCashCustomer),
  );

  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'importReference'),
    )?.type !== ImportExportType.EnvelopeId
  ) {
    columns.delete(ColumnId.ImportReference);
  }
  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'exportReference'),
    )?.type !== ImportExportType.EnvelopeId
  ) {
    columns.delete(ColumnId.ExportReference);
  }
  return columns;
};

export const getCreateFormFieldsToShow = (state: CreateFormState): BookingFields => {
  const createFieldCollection = flatMap(state.bookings, booking => [
    ...getCreateCandidateFormFields(booking, state),
  ]);

  return new Set(createFieldCollection);
};

export interface SpecificTableColumn {
  id: ColumnId;
  label: string;
  description?: string;
  onlyShowInExtendedMode: boolean;
  width: number;
}

export const specificTableColumns: SpecificTableColumn[] = [
  {
    id: ColumnId.VehicleRegNo,
    label: 'Vehicle reg.',
    onlyShowInExtendedMode: false,
    width: 130,
    description: 'Vehicle registration number',
  },
  {
    id: ColumnId.TrailerRegNo,
    label: 'Trailer reg.',
    onlyShowInExtendedMode: false,
    width: 130,
    description: 'Trailer registration number',
  },
  {
    id: ColumnId.CustomerReference,
    label: 'Customer reference',
    onlyShowInExtendedMode: false,
    width: 160,
  },
  {
    id: ColumnId.HazardousGoods,
    label: 'Haz. goods',
    onlyShowInExtendedMode: false,
    width: 100,
    description: 'Hazardous goods',
  },
  {
    id: ColumnId.LivestockTypeCode,
    label: 'Animals',
    onlyShowInExtendedMode: false,
    width: 135,
  },
  {
    id: ColumnId.Temperature,
    label: 'Temperature',
    onlyShowInExtendedMode: false,
    width: 110,
    description: 'Temperature onboard (ºC)',
  },
  {
    id: ColumnId.CargoWeight,
    label: 'Cargo weight',
    onlyShowInExtendedMode: false,
    width: 100,
    description: 'Cargo weight (kg)',
  },
  {
    id: ColumnId.LoadingPreferenceCode,
    label: 'Loading preference',
    onlyShowInExtendedMode: false,
    width: 160,
  },
  {
    id: ColumnId.NoOfDrivers,
    label: 'Drivers',
    onlyShowInExtendedMode: false,
    width: 76,
  },
  {
    id: ColumnId.CustomsClassification,
    label: 'UK customs procedure',
    onlyShowInExtendedMode: false,
    width: 240,
    description: 'UK customs procedure (NL-UK)',
  },
  {
    id: ColumnId.SenderCountryCode,
    label: 'Sender country',
    onlyShowInExtendedMode: false,
    width: 125,
  },
  {
    id: ColumnId.ReceiverCountryCode,
    label: 'Receiver country',
    onlyShowInExtendedMode: false,
    width: 125,
  },
  {
    id: ColumnId.VehicleTypeCode,
    label: 'Vehicle type',
    onlyShowInExtendedMode: true,
    width: 145,
  },
  {
    id: ColumnId.Length,
    label: 'Length',
    onlyShowInExtendedMode: true,
    width: 70,
    description: 'Length (m)',
  },
  {
    id: ColumnId.Width,
    label: 'Width',
    onlyShowInExtendedMode: true,
    width: 70,
    description: 'Width (m)',
  },
  {
    id: ColumnId.Height,
    label: 'Height',
    onlyShowInExtendedMode: true,
    width: 70,
    description: 'Height (m)',
  },
  {
    id: ColumnId.ExportReference,
    label: 'Export (to/from UK)',
    onlyShowInExtendedMode: false,
    width: 170,
  },
  {
    id: ColumnId.ImportReference,
    label: 'Import (to/from UK)',
    onlyShowInExtendedMode: false,
    width: 170,
  },
  {
    id: ColumnId.TradeWeight,
    label: 'Trade weight',
    onlyShowInExtendedMode: false,
    width: 100,
    description: 'Trade weight (kg)',
  },
];

export const getCreateColumnsToShow = (
  allColumns: SpecificTableColumn[],
  fieldsToShow: BookingFields,
): SpecificTableColumn[] => {
  if (allColumns.length === 0 || fieldsToShow.size === 0) {
    return [];
  }

  return allColumns.filter(column => fieldsToShow.has(column.id));
};
