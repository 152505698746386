import { VehicleType } from '../graphql/fragments/gql/VehicleType';
import { createMockCreator } from './mockUtils';

export const vehicleTypeMockArtic: VehicleType = {
  id: 'AR',
  code: 'AR',
  defaultHeight: 4,
  defaultLength: 17,
  defaultTradeWeight: 0,
  defaultWidth: 2.6,
  description: 'Artic',
  useAnimals: true,
  useCargoWeight: true,
  useHazardous: true,
  useNoOfDrivers: true,
  useTemperature: true,
  useTradeWeight: false,
  useTrailerReg: true,
  useVehicleReg: true,
};
export const vehicleTypeMockTrailer: VehicleType = {
  id: 'AR',
  code: 'TR',
  description: 'Trailer',
  defaultHeight: 4,
  defaultLength: 14,
  defaultTradeWeight: 0,
  defaultWidth: 2.6,
  useAnimals: false,
  useCargoWeight: true,
  useHazardous: true,
  useNoOfDrivers: false,
  useTemperature: true,
  useTradeWeight: false,
  useTrailerReg: false,
  useVehicleReg: true,
};
export const createVehicleTypeMockArtic = createMockCreator(vehicleTypeMockArtic);
export const createVehicleTypeMockTrailer = createMockCreator(vehicleTypeMockTrailer);
export const createVehicleTypeMock = createMockCreator(vehicleTypeMockArtic);
