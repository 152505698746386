import { routeCreators } from '../../../common/routes/AppRoutes';
import { AuthResult } from '../../../use-cases/auth/types';
import { AccountQuery_viewer_account_byEmail_permissions } from '../../../use-cases/settings/graphql/gql/AccountQuery';

export const resolveUserDisplayName = (authResult: AuthResult | null) => {
  return authResult ? authResult.idTokenPayload.sub.replace('auth0|EXTRANET\\', '') : '';
};

export const resolveUserName = (authResult: AuthResult | undefined | null): string => {
  return authResult ? authResult.idTokenPayload.sub.replace('auth0|', '') : '';
};

export enum Permission {
  ViewBookings,
  EditBookings,
  Statistics,
  Invoices,
  ViewIntermodalBookings,
  EditIntermodalBookings,
  Payment,
  PriceCalculation,
}

export const getOverrideMainPage = (
  permissions: AccountQuery_viewer_account_byEmail_permissions,
): string | undefined => {
  if (getHasPermission(Permission.EditBookings, permissions)) {
    return undefined;
  } else if (
    getHasPermission(Permission.ViewIntermodalBookings, permissions) ||
    getHasPermission(Permission.EditIntermodalBookings, permissions)
  ) {
    return routeCreators.intermodal();
  } else if (getHasPermission(Permission.Invoices, permissions)) {
    return routeCreators.financialDocuments();
  } else {
    return undefined;
  }
};

export const getHasPermission = (
  permission: Permission,
  permissions: AccountQuery_viewer_account_byEmail_permissions,
) => {
  switch (permission) {
    case Permission.ViewBookings:
      return permissions.viewBookings;
    case Permission.EditBookings:
      return permissions.editBookings;
    case Permission.PriceCalculation:
      return permissions.priceCalculation;
    case Permission.Statistics:
      return permissions.statistics;
    case Permission.Invoices:
      return permissions.invoices;
    case Permission.EditIntermodalBookings:
      return permissions.editIntermodalBookings;
    case Permission.ViewIntermodalBookings:
      return permissions.viewIntermodalBookings;
    default:
      return false;
  }
};
