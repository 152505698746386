import { FlatButton, stenaCheck, stenaPen } from '@stenajs-webui/elements';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { EnsButtonLink } from '../../../../common-ui/import-export/EnsButtonLink';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useUpdateSubmit } from '../../../../common/update-information/hooks/useUpdateSubmit';
import { openOptionalEnsAfterSave } from '../../../../services/update-form/updateFormActions';
import { getFormIsEdited } from '../../../../services/update-form/updateFormReducer';
import { useAppDispatch } from '../../../../store/appDispatch';
import { StoreState } from '../../../../store/storeState';

interface BookingUpdateEnsButtonProps {
  booking: BasicBooking;
}

export function BookingUpdateEnsButton({ booking }: BookingUpdateEnsButtonProps) {
  const dispatch = useAppDispatch();

  const mapState = useCallback(
    (state: StoreState) => {
      return getFormIsEdited(state, {
        bookingNo: booking.bookingNo,
        formId: 'Details',
      });
    },
    [booking.bookingNo],
  );

  const edited = useSelector(mapState);

  const { submitting } = useUpdateSubmit({
    bookingNo: booking.bookingNo,
    formId: 'Details',
  });

  return edited ? (
    <FlatButton
      className={'t_ens_button_link'}
      disabled={submitting}
      onClick={() =>
        dispatch(
          openOptionalEnsAfterSave({
            bookingNo: booking.bookingNo,
            formId: 'Details',
          }),
        )
      }
      label={booking.importReference.ensConfirmed ? 'ENS Confirmed' : 'Declare ENS (optional)'}
      leftIcon={booking.importReference.ensConfirmed ? stenaCheck : stenaPen}
    />
  ) : (
    <EnsButtonLink
      ensUrl={booking.ensUrl}
      confirmed={booking.importReference.ensConfirmed}
      trackerCategory={'Details'}
      optional
    />
  );
}
