import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserNameWithPrefix } from '../../services/authentication/authReducer';
import { getCookieConsent } from '../../services/cookie-consent/slice';
import { TrackerCategory, trackEvent } from '../tracking/trackerService';
import { resolveCustomsDocumentationEnsURL } from '../utils/customsEnsUtils';

export function useOptionalEnsLink(
  ensUrl: string,
  optional: boolean | undefined,
  trackerCategory: TrackerCategory,
) {
  const userId = useSelector(getUserNameWithPrefix);
  const consent = useSelector(getCookieConsent);

  const onClick = useCallback(() => {
    trackEvent({
      category: trackerCategory,
      action: `Pressed ${optional ? 'optional' : ''} ENS link`,
    });
  }, [optional, trackerCategory]);

  return {
    href: resolveCustomsDocumentationEnsURL(ensUrl, userId, consent),
    onClick,
  };
}
