import { Auth0DecodedHash } from 'auth0-js';
import { Store } from 'redux';
import { Persistor } from 'redux-persist/es/types';
import { routeCreators } from '../../common/routes/AppRoutes';
import {
  fatalError,
  noSessionFound,
  userLoggedIn,
  userLoggedOut,
} from '../../services/authentication/authActions';
import { Auth } from './Auth';
import { ErrorType } from './errors';
import { OfflineAuth } from './OfflineAuth';
import { AuthInterface, AuthParams, AuthResult } from './types';

export const getAuthCallbackParams = (
  store: Store<any, any>,
  persistor: Persistor,
): AuthParams => ({
  logIn(authResult: AuthResult) {
    store.dispatch(userLoggedIn(authResult));
    persistor.persist();
  },
  logOut() {
    store.dispatch(userLoggedOut());
    localStorage.clear();
  },
  onRenewSessionFail() {
    store.dispatch(noSessionFound());
  },
  onFatalError(error: ErrorType | undefined, description: string) {
    if (error) {
      store.dispatch(fatalError(error, description));
    }
  },
});

export const getAuth = (store: Store<any, any>, persistor: Persistor): AuthInterface => {
  const authParams = getAuthCallbackParams(store, persistor);

  if (import.meta.env.REACT_APP_MOCK_LOGIN) {
    return new OfflineAuth(authParams);
  }

  return new Auth(authParams);
};

export const handleAuthOnLoad = async (
  auth: AuthInterface,
): Promise<Auth0DecodedHash | undefined> => {
  if (
    window.location.pathname === routeCreators.callback() &&
    /access_token|id_token|error/.test(window.location.hash)
  ) {
    return auth.handleAuthentication();
  }

  return auth.checkSession();
};
