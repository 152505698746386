import { AuthInterface, AuthParams } from './types';

export class OfflineAuth implements AuthInterface {
  private params: AuthParams;
  private redirectedFromCallbackUrl: boolean = false;

  constructor(params: AuthParams) {
    this.params = params;
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);

    this.params.onRenewSessionFail();
  }

  public async checkSession() {
    this.redirectedFromCallbackUrl = false;
    this.login();

    return undefined;
  }

  public async handleAuthentication() {
    this.redirectedFromCallbackUrl = true;
    this.login();

    return {};
  }

  public login() {
    this.params.logIn({
      expiresAt: new Date().getTime() + 7200 * 1000,
      idTokenPayload: {
        sub: 'auth0|EXTRANET\\OFFLINE_USER',
      },
      redirectedFromCallbackUrl: this.redirectedFromCallbackUrl,
    });
  }
  public logout() {
    this.params.logOut();
    this.params.onRenewSessionFail();
  }
}
