import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Column, Row, Space, TextProps, Txt } from '@stenajs-webui/core';
import {
  Banner,
  Cardy,
  CardyBody,
  Icon,
  stenaCalendar,
  stenaStatusNoShow,
  ValueTable,
} from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { parseISO } from 'date-fns';
import { FC, ReactNode, useMemo } from 'react';
import { EnsButtonLink } from '../../../../common-ui/import-export/EnsButtonLink';
import {
  InformationMessage,
  resolveInformationMessageBannerVariant,
} from '../../../../common-ui/information-message/informationMessageUtils';
import { FlatButtonLink } from '../../../../common-ui/Link';
import { PortDetailsForSailing } from '../../../../common-ui/PortDetailsForSailing';
import { getShortArrivalInformation } from '../../../../common-ui/sailing-info/utils/getExtendedArrivalInformation';
import { ShadowlessCard } from '../../../../common-ui/ShadowlessCard';
import { TripDetails } from '../../../../common-ui/TripDetails';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { Sailing } from '../../../../common/graphql/fragments/gql/Sailing';
import { useBackgroundLocation } from '../../../../common/home/components/UseBackgroundLocation';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { getModalRouteLinkProps, modalRouteCreators } from '../../../../common/routes/AppRoutes';
import { useUpdateFormState } from '../../../../common/update-information/hooks/useUpdateFormState';
import { formatDateTime } from '../../../../common/utils/dates/dateUtils';
import { globalContentWrapperIndent } from '../../../../globalStyles';
import { BookingLateHandlingStatus } from '../../../../gql/graphql';
import { INTERMODAL_ICON } from '../../grid/utils/bookingSpecialProperties';
import { shouldShowShareBooking } from '../../share/util/shareUtil';
import { bookingDetailsRows, RowType } from '../utils/detailsRows';
import { BookingUpdateStatusMessage } from './BookingUpdateStatusMessage';
import { DetailsRow } from './DetailsRow';
import { InfoMessageDecider } from './InfoMessageDecider';
import { OptionalEnsCard } from './OptionalEnsCard';
import { ShareBookingCard } from './ShareBookingCard';

export interface BookingDetailsViewProps {
  booking: DetailedBooking;
  statusInformationList: InformationMessage[];
}

const ValueTableWrapper = styled(Cardy)`
  table {
    table-layout: fixed;
  }
`;

const renderRow: (row: RowType) => ReactNode = ({
  className,
  name,
  value,
  suffix,
  collapse = false,
}) => (
  <DetailsRow
    key={name}
    name={name}
    collapse={collapse}
    value={value}
    className={className}
    suffix={suffix}
  />
);

export const BookingDetailsView: FC<BookingDetailsViewProps> = ({
  booking,
  statusInformationList,
}) => {
  const { updateSubmitInformation } = useUpdateFormState({
    formId: 'Details',
    bookingNo: booking.bookingNo,
  });

  const { lateHandlingStatus } = booking;

  const dateFormat = useLocalDateFormat();

  const location = useBackgroundLocation();

  const rows = useMemo(() => bookingDetailsRows(booking, dateFormat), [booking, dateFormat]);

  const vehicleRegNumber = booking.vehicleRegNo || booking.trailerRegNo;

  const arrivalInformation = booking.sailing
    ? getShortArrivalInformation(booking.hasArrived, booking.sailing, dateFormat)
    : null;

  return (
    <Box className={'t_booking_details_view'}>
      <Box indent={globalContentWrapperIndent} spacing={2} maxWidth={750}>
        <PortDetailsForSailing
          sailing={booking.sailing}
          bookingStatus={booking.bookingStatus.status}
        />
      </Box>

      <Box
        className={'t_banner_content_view_modal'}
        maxWidth={980}
        indent={globalContentWrapperIndent}
        spacing
        gap
      >
        {booking.referenceBookingNo > 0 && (
          <Banner
            icon={INTERMODAL_ICON}
            variant={'info'}
            text={`This booking is part of intermodal booking ${booking.referenceBookingNo}.`}
            contentRight={
              <FlatButtonLink
                className={'t_intermodal_open_booking_link'}
                {...getModalRouteLinkProps(
                  modalRouteCreators.intermodalDetails(
                    { bookingNo: booking.referenceBookingNo },
                    location,
                  ),
                )}
                label={'Open intermodal booking'}
              />
            }
          />
        )}

        <BookingUpdateStatusMessage
          updateSubmitInformation={updateSubmitInformation}
          submitting={false}
        />

        {statusInformationList.map((statusInformation, index) => (
          <Banner
            key={index}
            variant={resolveInformationMessageBannerVariant(statusInformation.type)}
            headerText={statusInformation.message}
          />
        ))}
        <InfoMessageDecider booking={booking} updateSubmitInformation={updateSubmitInformation} />
      </Box>

      <Box indent={globalContentWrapperIndent} flexDirection={['column', null, 'row']} gap={2}>
        <ValueTableWrapper maxWidth={[null, null, 600]} flex={2}>
          <CardyBody>
            <ValueTable disableBorder variant={'condensed'}>
              {rows.map(row => renderRow(row))}
            </ValueTable>
          </CardyBody>
        </ValueTableWrapper>

        <Column justifyContent={'space-between'} gap={2}>
          <SailingDetails
            sailing={booking.sailing}
            releasedAt={booking.releasedAt}
            arrivalInformation={arrivalInformation}
            dateFormat={dateFormat}
            lateHandlingStatus={lateHandlingStatus}
            dateModified={booking.dateModified}
          />
          <Column width={[null, null, 'min-content']}>
            <OptionalEnsCard booking={booking}>
              <EnsButtonLink
                ensUrl={booking.ensUrl}
                confirmed={booking.importReference.ensConfirmed}
                trackerCategory={'Details'}
                optional
              />
            </OptionalEnsCard>
            {shouldShowShareBooking(
              vehicleRegNumber,
              booking.bookingStatus.status,
              booking.bookingQuayStatus?.status,
            ) && (
              <ShareBookingCard bookingNo={booking.bookingNo} vehicleRegNo={booking.vehicleRegNo} />
            )}
          </Column>
        </Column>
      </Box>
      <Space num={2} />
    </Box>
  );
};

interface SailingDetailsProps {
  sailing: Sailing;
  releasedAt: string | null;
  arrivalInformation: string | null;
  dateFormat: string;
  lateHandlingStatus: BookingLateHandlingStatus | null;
  dateModified: string;
}

const SailingDetails = ({
  sailing,
  dateFormat,
  dateModified,
  releasedAt,
  lateHandlingStatus,
  arrivalInformation,
}: SailingDetailsProps) => (
  <Box gap={2}>
    <ShadowlessCard indent={2} spacing={3} gap={1}>
      <TripDetails
        shipName={sailing.shipName}
        releasedAt={releasedAt}
        sailingType={sailing.sailingType}
        arrivalInformation={arrivalInformation}
        dateFormat={dateFormat}
      />
    </ShadowlessCard>
    <Box gap={1} indent={2}>
      {lateHandlingStatus && (
        <BookingValueRow
          icon={stenaStatusNoShow}
          label={lateHandlingStatus.label}
          value={formatDateTime(parseISO(lateHandlingStatus.statusDate), dateFormat)}
          color={cssColor('--lhds-color-red-500')}
        />
      )}
      <BookingValueRow
        icon={stenaCalendar}
        label={'Last updated'}
        value={formatDateTime(parseISO(dateModified), dateFormat)}
      />
    </Box>
  </Box>
);

interface BookingLabelWithDateProps extends Pick<TextProps, 'color'> {
  label: string;
  value: string;
  icon: IconDefinition;
}

const BookingValueRow: FC<BookingLabelWithDateProps> = ({ value, label, color, icon }) => (
  <Row alignItems={'center'} gap={2}>
    <Icon color={color} icon={icon} size={20} />
    <Txt>
      <Txt color={color}>{label}</Txt>
      <Txt> – </Txt>
      <Txt>{value}</Txt>
    </Txt>
  </Row>
);
