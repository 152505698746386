import { Box, Column, Heading, Row, Space, Txt } from '@stenajs-webui/core';
import { CloseButton, SecondaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../common-ui/images/logo_blue_text.svg';
import { trackEvent } from '../../common/tracking/trackerService';
import { getBookingsFilterQueryResult } from '../../services/grid/gridReducer';
import { requestStopWelcomeTour, startWelcomeTour } from '../../services/tour/tourActions';

export interface IntroductionModalProps {
  onClose: () => void;
}

export const WelcomeModal: FC<IntroductionModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const bookingQueryResult = useSelector(getBookingsFilterQueryResult);
  const gridResultAvailable = bookingQueryResult?.result;

  const onStartTourClick = () => {
    trackEvent({ category: 'WelcomeTour', action: 'Start' });
    dispatch(startWelcomeTour());
    onClose();
  };

  const onCloseClick = () => {
    trackEvent({ category: 'WelcomeTour', action: 'Ignore' });
    dispatch(requestStopWelcomeTour());
    onClose();
  };

  if (!gridResultAvailable) {
    return null;
  }

  return (
    <Column className={'t_introduction_tour_modal'} width={624} maxWidth={'100%'} gap={3}>
      <Row justifyContent={'space-between'}>
        <Box indent={3} spacing={3}>
          <img style={{ width: 120 }} src={logo} alt={'Stena Line'} />
        </Box>
        <Box spacing={1} indent={1}>
          <CloseButton onClick={onCloseClick} />
        </Box>
      </Row>
      <Row justifyContent={'center'}>
        <Column indent={3} gap={1}>
          <Heading variant={'h2'}>Welcome to Stena Line Freight portal!</Heading>
          <Txt>
            With Freight portal, you can easily <b>create, manage, and keep track</b> of all your
            bookings in one place. Our tools provide a complete overview of your freight business,
            ensuring you stay organized and efficient.
          </Txt>
          <Txt>
            The user-friendly Stena Line products are designed to simplify your daily tasks, making
            your experience quicker and smoother from start to finish.
          </Txt>
          <Txt>
            Access <b>Freight portal from your desktop</b> or use our convenient{' '}
            <b>mobile Planner app</b> for on-the-go management. With a few clicks, you can share
            your bookings directly to the <b>Driver app</b>, which will provide your drivers with
            all necessary information.
          </Txt>
          <Txt>
            In many of our ports we also offer a seamless <b>Smart Gate</b> system, allowing quick
            passage in and out.
          </Txt>
          <Txt variant={'bold'}>Let’s get started with your bookings!</Txt>
        </Column>
      </Row>
      <Box spacing={2} alignSelf={'center'}>
        <SecondaryButton
          autoFocus
          className={'t_start_introduction_tour'}
          disabled={!gridResultAvailable}
          label={'Start the introduction'}
          onClick={onStartTourClick}
        />
        <Space num={2} />
      </Box>
    </Column>
  );
};
