import { useSelector } from 'react-redux';
import { assertIsDefined } from '../../../common/asserts/assert';
import { AccountQuery_viewer_account_byEmail } from '../../../use-cases/settings/graphql/gql/AccountQuery';
import { getAccount } from '../settingsReducer';

export const useAccount = (): AccountQuery_viewer_account_byEmail => {
  const account = useSelector(getAccount);

  assertIsDefined(account, 'Account is not initialized');

  return account;
};

export const useUserLoginEmail = () => useAccount().accountSettings.accountLoginEmail;
