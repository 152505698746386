import { assignWith } from 'lodash';
import { batch } from 'react-redux';
import { loadFilter } from '../../../common/urlFilterListener';
import { AppThunk } from '../../../store/createAppThunk';
import { getDefaultSortDirection } from '../../../use-cases/manage/grid/utils/getDefaultSortDirection';
import { sortBookings } from '../../sorting/sortingActions';
import { initialFilterApplied, setFilter } from '../filterActions';
import { FilterState } from '../filterReducer';

export const setFilterFromUrlWithSettingsFallback =
  (filterState: FilterState): AppThunk =>
  dispatch => {
    const { filter: filterFromURL, sorting: sortingFromURL } = loadFilter();

    batch(() => {
      if (sortingFromURL?.column) {
        dispatch(
          sortBookings(
            sortingFromURL.column,
            sortingFromURL.direction ?? getDefaultSortDirection(sortingFromURL.column),
          ),
        );
      }

      const mergedFilterState = assignWith(
        { ...filterState },
        filterFromURL,
        (objValue, srcValue) => {
          return srcValue != null ? srcValue : objValue;
        },
      );
      dispatch(setFilter(mergedFilterState));
      dispatch(initialFilterApplied());
    });
  };
