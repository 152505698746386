import { first, map } from 'lodash';
import { matchRoutes, RouteMatch } from 'react-router-dom';
import { browserHistory } from '../../store/utils/history';
import { Location, ModalRouteLocations, modalRouteLocations } from './AppRoutes';

export const getModalRouteFromPath = ({ pathname }: Location) => {
  const r = matchRoutes(
    map(modalRouteLocations, (route, key) => ({ path: route.path.pathname, id: key })),
    pathname,
  );

  return first(r);
};

export const redirectModal = (route: RouteMatch, { pathname }: Location) => {
  const appRouteKey = route.route.id as keyof ModalRouteLocations;
  const modalRoute = modalRouteLocations[appRouteKey];

  browserHistory.replace(modalRoute.state.background);
  browserHistory.push(pathname, modalRoute.state);
};
