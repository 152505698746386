import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import {
  startPollingFrontendOutOfDateAction,
  stopPollingFrontendOutOfDateAction,
} from '../../../services/frontend-out-of-date/frontendOutOfDateActions';
import { showHotkeysOverview } from '../../../services/modal/modalActions';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useHotkeys } from '../../hotkeys/hooks/useHotkeys';
import { Hotkeys } from '../../hotkeys/utils/hotkeys';
import { routeCreators } from '../../routes/AppRoutes';
import { trackEvent } from '../../tracking/trackerService';
import { saveURLFilterData } from '../../urlFilterListener';

const selector = (state: StoreState) => ({ filter: state.filter.filter, sorting: state.sorting });

const useSaveBookingsFilterInURLHash = () => {
  const isMatch = useMatch({ path: routeCreators.bookings(), end: true }) != null;

  const selectedFilter = useShallowEqualSelector(selector);

  useEffect(() => {
    if (isMatch) {
      saveURLFilterData(selectedFilter.filter, selectedFilter.sorting);
    }
  }, [selectedFilter, isMatch]);
};

const useHotkeysOverview = () => {
  const dispatch = useDispatch();

  useHotkeys(Hotkeys.HotkeysOverView, () => {
    trackEvent({
      category: 'ShortKeys',
      action: Hotkeys.HotkeysOverView,
      label: 'Open short key overview',
    });

    dispatch(showHotkeysOverview());
  });
};

const useFrontendOutOfDatePolling = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startPollingFrontendOutOfDateAction());

    return () => {
      dispatch(stopPollingFrontendOutOfDateAction());
    };
  }, [dispatch]);
};

export const SideEffects = () => {
  useSaveBookingsFilterInURLHash();
  useHotkeysOverview();
  useFrontendOutOfDatePolling();

  return null;
};
